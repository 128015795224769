import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { Header } from '../../components/header';
import { Ticker } from '../../components/ticker';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default class Page extends React.Component {
  constructor () {
    super();
    this.state = {};
  }

  get className () {
    return classNames({
      'homepage': true
    });
  }

  get website () {
    return this.props.pageContext.website;
  }

  get show () {
    return this.props.pageContext.show;
  }

  get videoId () {
    if (!this.show.vimeoLink) return false;
    return this.show.vimeoLink.split('/').pop();
  }

  get vimeoUri () {
    if (!this.videoId) return false;
    return [
      'https://player.vimeo.com/video/',
      this.videoId,
      '?autoplay=1&autopause=0&muted=1&loop=1'
    ].join('');
  }

  render = () => (
    <>
     <Helmet>
        <title>{`${this.show.title} - ${this.website.title}`}</title>
        <meta name="description" content={this.website.description} />
        <meta property="og:image" content={'https:' + this.website.socialMediaImage.file.url} />
      </Helmet>
      <Header website={this.website} />
      <main className="show">
        <Ticker>{ this.show.ticker }</Ticker>
        <h1 className="show__title">{ this.show.title }</h1>

        { this.vimeoUri && (
          <div className="show__video">
            <iframe src={this.vimeoUri} frameborder="0" allow="autoplay; fullscreen" allowfullscreen />
          </div>
        ) }
        
        <section className="show__colums show__colums--text">
          <div>{ documentToReactComponents(this.show.textColumnLeft) }</div>
          <div>{ documentToReactComponents(this.show.textColumnRight) }</div>
        </section>

        <section className="show__colums show__colums--image">
          <div>{ this.show.imageLeft?.file?.url && <img src={this.show.imageLeft?.file?.url} /> }</div>
          <div>{ this.show.imageRight?.file?.url && <img src={this.show.imageRight?.file?.url} /> }</div>
        </section>

        <section className="show__jury">
          <div className="show__jury__line" />
          <h2 className="show__jury__title">{ this.show.juryTitle }</h2>

          <div className="show__jury__columns">
            <div>{ documentToReactComponents(this.show.juryColumn1) }</div>
            <div>{ documentToReactComponents(this.show.juryColumn2) }</div>
            <div>{ documentToReactComponents(this.show.juryColumn3) }</div>
          </div>
        </section>

        { this.show.largeImage?.file?.url && <img className="show__big-image" src={ this.show.largeImage?.file?.url } />}

        <section className="show__prod">
          <h2 className="show__jury__title">{ this.show.productionTitle }</h2>

          <div className="show__jury__columns">
            <div>{ documentToReactComponents(this.show.productionColumn1) }</div>
            <div>{ documentToReactComponents(this.show.productionColumn2) }</div>
            <div>{ documentToReactComponents(this.show.productionColumn3) }</div>
          </div>
        </section>

        <section className="show__number">
          <a href="5"><span>5</span></a>
          <a href="4"><span>4</span></a>
          <a href="3"><span>3</span></a>
          <a href="2"><span>2</span></a>
          <a href="1"><span>1</span></a>
        </section>
      </main>
    </>
  )
}